<template>
        <!-- 存料提纯 -->
  <div class="keepRecord">
         <van-tabs v-model="active"  animated sticky>
            
            <van-tab title="存料管理">
                <keep-alive>
                    <keepAdmin />
                </keep-alive>
            </van-tab>
            <van-tab title="存料结算">
                <keep-alive>
                         <keepJiesuan />
                </keep-alive>
            </van-tab>
         </van-tabs>
         <!-- 左侧图标 -->
         <div class="tabsLeftIcon" @click="ToPerson">
            <van-icon name="arrow-left" />
         </div>
         <div class="top" v-if="active =='0'?true:false">
            <div class="startTime">开始时间</div>
            <div class="weight">重量(g)</div>
            <div class="realTlmeMOney">实时应收(元)</div>
            <div class="state">状态</div>
        </div>
  </div>
</template>

<script>
import keepAdmin from './components/keepAdmin.vue'
import keepJiesuan from './components/keepJiesuan.vue'
export default {
    components:{
        keepAdmin,
        keepJiesuan
    },
            data() {
                return {
                    active:'0'
                }
            },
  mounted() {
    this.$route.query.active?this.active =  this.$route.query.active:this.active = '0'
  },
            methods:{
                ToPerson(){
                    this.$router.push({
                        name:'person'
                    })
                }
            }
}
</script>

<style lang="scss" scoped>
::v-deep{
   
    .van-tabs__wrap{
        height: 88px;
        padding: 0 220px;
        background-color: #fff;
    }
    .van-tabs__line{
        display: none;
    }
    .van-tabs__nav--line {
        padding-bottom: 0 !important;
}
    .van-tabs__nav{
        border: 1px solid #ff2d2e;
        height: 50px;
        margin-top: 16px;
        border-radius: 10px ;
    }
    .van-tab{
        height: 100%;
        color: #ff2d2e;
        line-height: 50px;
        font-size: 28px;
        padding: 0;
    }
    .van-tab--active{
         height: 100%;
        line-height: 50px;
        font-size: 28px;
        padding: 0;
        background-color: #ff2d2e;
        color: #fff;
    }
}
.tabsLeftIcon{
    position: fixed;
    top: 30px;
    left: 10px;
    z-index: 9999;
    .van-icon{
        font-size: 32px;
    }
}
.keepRecord {
  background-color: #f7f7f7;
  height: 100%;
}
.top{
            width: 100%;
            padding: 30px 50px 16px 30px;
            display: flex;
            position: fixed;
            top: 72px;
            left: 0;
            justify-content: space-between;
            //border-bottom: 1px solid #ccc;
            color: #1a1a1a;
            //font-weight: 600;
            //background-color: #fff;
            text-align: center;
            font-size: 28px;
                .startTime {
                     margin-left: 30px;
                }
                .weight {
                     margin-left: 20px;
                }
                .realTlmeMOney{
                     margin-left: 20px;

                }
        }
</style>